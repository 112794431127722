import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import * as API from '../../Components/util/url'
import './Otpmodal.css';

const Otpmodal = (props) => {
  const navigate = useNavigate();
  const { email, ...modalProps } = props;
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
  const [otpExpired, setOtpExpired] = useState(false); // OTP expiration state

  // console.log(email);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup timer on unmount
    } else {
      setOtpExpired(true); // Set OTP expired when timer hits 0
    }
  }, [timeLeft]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      let newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (/^[0-9]$/.test(value) || value === "") {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onSubmit = async () => {
    if (otpExpired) {
      setError("OTP has expired. Please request a new one.");
      return;
    }

    if (otp.includes("")) {
      setError("All OTP fields must be filled.");
    } else {
      setError("");

      try {
        const response = await API.verifyotp(otp.join(''), email);
        props.onHide();
        if (response.data.status === true) {
          navigate('/signin');
        } else {
          setError("Incorrect OTP. Please try again.");
        }
      } catch (error) {
        console.log("Error:", error);
        setError("Error verifying OTP. Please try again later.");
      }
    }
  };

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  // Resend OTP and reset the timer
  const handleResendOtp = async () => {
    try {
      let resendResp = await API.Resendotp(email);
      if (resendResp.data.status === true) {
        setOtpExpired(false); // Reset OTP expiration
        setTimeLeft(120); // Restart the timer for 2 minutes
        setOtp(["", "", "", ""]); // Clear the OTP input fields
        setError(""); // Clear error
      } else {
        setError("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setError("Error resending OTP. Please try again later.");
    }
  };

  return (
    <Modal {...modalProps} size="small" centered>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4" style={{ minWidth: 500 }}>
            <div className="card bg-white border-0" style={{ boxShadow: "0 12px 15px rgba(0, 0, 0, 0.02)" }}>
              <div className="card-body text-center">
                <h4>Verify</h4>
                <p>We have sent a verification code to your email address: {email}</p>
                <p style={{ fontSize: '12px', color: 'red' }}>
                  Note: If the mail does not appear in the inbox, please check the spam section for the mail.
                </p>
                
                <div className="otp-field mb-4">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="number"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleChange(e, index)}
                      id={`otp-input-${index}`}
                      ref={(input) => inputRefs.current[index] = input}
                      disabled={otpExpired} // Disable input when OTP expired
                    />
                  ))}
                </div>
                
                {error && <div className="error-message text-danger mb-3">{error}</div>}
                
                {!otpExpired && <p>Time remaining: {formatTimeLeft()}</p>}
                {otpExpired && <p className="text-danger">OTP has expired. Please request a new one.</p>}

                <button className="btn verify mb-3" onClick={onSubmit} disabled={otpExpired}>
                  Verify
                </button>
                
                <p className="resend text-muted mb-0">
                  Didn't receive code? <button className="resend-otp" onClick={handleResendOtp}>Resend OTP</button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Otpmodal;
