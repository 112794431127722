import axios from "axios";

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000";
  } else if (process.env.NODE_ENV === "production") {
    return "/";
  }
};

const API = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// It helps to send Token through headers
API.interceptors.request.use((req) => {
  if (localStorage?.getItem("afhnew")) {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem("afhnew")}`;
  }
  return req;
});

// Auth Api
export const SignUp = (data) => API.post("/api/auth/SignUp", data);
export const SignIn = (data) => API.post("/api/auth/SignIn", data);
export const verifyotp = (otp,data) => API.get(`/api/auth/verifyOtp/${otp}/${data}`);
export const Resendotp = (data) => API.get(`/api/auth/resendOtp/${data}`);
export const Sendotp = (data) => API.get(`/api/auth/sendOtp/${data}`);

// filter api USER


export const AddWishList = (data) => API.post("/user/addWishlist", data);



export const addReview = (data) => API.post(`/user/addReview`,data);
export const editProfile = (data) => API.post(`/user/editProfile`,data);
export const createlisting = (data) => API.post(`/user/createListing`,data);

// get api


export const getWishlist = (data) => API.get(`/user/getWishlist`);





// admin controller
export const AdminContactus=(data)=>API.post(`/admin/getAllMessages`,data);
export const AdminAddBlog=(data)=>API.post(`/admin/addBlog`,data);
export const GetDashboard=(data)=>API.get(`/admin/getDashBoard`);
export const GetAllblogsforadmin=(data)=>API.get(`/admin/getAllBlogsForAdmin`);
export const GetAllAccounts=(data)=>API.post(`/admin/getAllAccounts`,data);
export const GetAllListing=(data)=>API.post(`/admin/getAllListings`,data);
export const DeleteListingById=(data)=>API.put(`/admin/deleteListingsById`,data);
export const DeleteAccountById=(data)=>API.put(`/admin/deleteAccountsById`,data);
export const AdminContactusDelete=(data)=>API.delete(`/admin/deleteMessage/${data}`);
export const AdminDeleteBlog=(data)=>API.delete(`/admin/deleteBlog/${data}`);
export const AdminContactusrespond=(data)=>API.put(`/admin/markAsResponded/${data}`);
export const AdminPublishBlog=(data)=>API.put(`/admin/publishBlog/${data}`);
export const AdminUpdateBlog=(data)=>API.put(`/admin/updateBlog`,data);




// owner controller
export const getListedhome=(data)=>API.get(`/owner/getAllHomesByOwner`);
export const getDashBoardowner=(data)=>API.get(`/owner/getOwnerDashBoard`);
export const getUpdateListing=(data)=>API.post(`/owner/updateListing`,data);


// front controller
export const GetReviewsByHomeId = (data) => API.post("/front/getReviewsByHomeId", data);
export const GetNearByHomes = (data) => API.post("/front/getNearByHomes", data);
export const HomeListFilter = (data) => API.post("/front/filter", data);
export const ContactUs = (data) => API.post("/front/contactUs", data);
export const CheckAvaliblity = (data) =>
  API.post("/front/checkAvaliblity", data);
export const GetHomeById = (data) => API.get(`/front/getHomeById/${data}`);
export const getCityNames = (data) => API.get(`/front/getCityNames/${data}`);
export const MarkerMapList = (data) => API.get(`/front/getAllLocations`);
export const GetAllBlogs = (data) => API.get(`/front/getAllBlogs`);
export const GetAllBlogsById = (data) => API.get(`/front/getBlogById/${data}`);