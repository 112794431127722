import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './ForgetPassword.css';
import * as API from '../../Components/util/url';
import CommonModal from '../../Common/CommonModal/CommonModal';
import CommonModalFail from '../../Common/CommonModal/CommonModalFail';


const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
});

const ForgetPassword = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });

  const otpsend = async (email) => {
    try {
      let response = await API.Sendotp(email);
      if(response.data.status===true){
        setMessage({  msg: response.data.message });
        setShowSuccessModal(true)
      }
      if(response.data.status===false){
        setMessage({ msg: response.data.message });
        setShowFailureModal(true);
      }
     
    } catch (error) {
    
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 m-auto col-forget">
          <div className="forgetpanel panel-default">
            <div className="panel-body">
              <div className="text-center">
                <h3>
                  <i className="fa fa-lock fa-4x" />
                </h3>
                <h2 className="text-center">Forgot Password?</h2>
                <p>You can reset your password here.</p>

                {/* Formik form integration */}
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                  
                    otpsend(values.email);
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form id="register-form" className="form">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="fas fa-user"></i>
                          </span>
                          <Field
                            id="email"
                            name="email"
                            placeholder="Email address"
                            className="form-control"
                            type="email"
                          />
                        </div>
                       
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-text"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <button
                          type="submit"
                          className="btn btn-block"
                          disabled={isSubmitting}
                        >
                          Reset Password
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
      show={showSuccessModal}
      onHide={() => setShowSuccessModal(false)}
      message={message}
      />
      <CommonModalFail
       show={showFailureModal}
       onHide={() => setShowFailureModal(false)}
       message={message}
      />
    </div>
  );
};

export default ForgetPassword;
