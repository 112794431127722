import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonNavbar from "../../Pages/CommonNavbar";
import "./HomeListingFilter.css";
import Footer from "../../Pages/Footer";
import { Button, Offcanvas } from "react-bootstrap";
import * as API from "../../Components/util/url";
import { Link } from "react-router-dom";
import PaginationWrapper from "../../Common/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../Components/util/store/wishlistSlice";
import "../MediaQuery/MediaQuery.css";

const HomeListingFilter = () => {
  const navigate = useNavigate();
  const { city } = useParams();
  const [show, setShow] = useState(false);
  const [wishlist, setWishlist] = useState({}); // Object to track wishlist state
  // const [avgRating, setAvgRating] = useState(0);
  // const [description, setDescription] = useState("");
  const [homeDetails, setHomeDetails] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [size, setSize] = useState(100);
  const [activePage, setActivePage] = useState(1);

  const [filters, setFilters] = useState({
    city: city,
    roomTypes: [],
    amenities: [],
    activities: [],
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalShow, setModalShow] = useState(false);

  const filterRoom = [
    "Private Room",
    "Room & Bath",
    "Room & Shower",
    "Semi-private Room",
    "Shared Main Shower",
  ];
  const activities = [
    // {amenities:'Activities coordinator'},
    "Games",
    "Arts and Crafts",
    "Birthday celebration",
    "Music",
  ];
  const amenities = [
    "Activities coordinator",
    "Telephone",
    "Internet access",
    "Wheelchair accessible",
    "Beautician",
    "Library on Wheels",
    "Tv in room",
    "Hoyer Lift",
    "Emergency call buttons",
    "Outdoor Common Area",
    "Cable TV",
    "Newspaper",
    "Generator",
    "Hospital Electric bed Available in the room",
  ];

  const filterlist = async (data) => {
    if (filters.city === "") return;
    try {
      const response = await API.HomeListFilter({
        pageNumber: activePage,
        size,
        city: data.city,
        roomTypes: data.roomTypes,
        amenities: data.amenities,
        activities: data.activities,
      });

      if (response.data.success && response.data.map.results.length > 0) {
        // setSize(response.data.map.results.length)
        setHomeDetails(response.data.map.results);
        setPageCount(Math.ceil(response.data.map.totalCount/size));
        navigate(`/${filters.city}`, { replace: true });
      } else {
        console.log("No results found or results is not an array.");
        setHomeDetails(response.data.map.results);
      }
    } catch (error) {
      console.error("API Call Error:", error.message || error);
    }
  };

  useEffect(() => {
    let timerOut=setTimeout(()=>{
      filterlist(filters);
    },800);

    return()=>clearTimeout(timerOut);

  }, [activePage, filters]);

  const handleWishlistToggle = async (index) => {
    if (localStorage.getItem("afhnew") == undefined) {
      ///yeha condition lagani hai
      navigate("/signin");
    } else {
      const stayId = homeDetails[index]?.stayId;
      if (!stayId) {
        console.error("stayId is undefined or invalid");
        return;
      }

      try {
        setWishlist((prev) => ({
          ...prev,
          [index]: !prev[index],
        }));

        await wishlistapi(stayId);
      } catch (error) {
        console.error("Error in handleWishlistToggle:", error);
      }
    }
  };

  const wishlistapi = async (stayId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await API.AddWishList(
        {
          stayId: stayId,
        },
        token
      );
    } catch (error) {
      console.error("Error in wishlistapi:", error);
    }
  };

  // read more

  const ReadMore = ({ text, maxLength }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };

    return (
      <div>
        {isTruncated ? (
          <p>
            {text.length > maxLength ? text.slice(0, maxLength) + "..." : text}
            <span
              onClick={toggleTruncate}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Read more
            </span>
          </p>
        ) : (
          <p>
            {text}
            <span
              onClick={toggleTruncate}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Read less
            </span>
          </p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const filterType = name.split("-")[1];

    if (type === "checkbox") {
      setFilters((prevFilters) => {
        const filterArray = prevFilters[filterType] || [];

        const updatedFilters = {
          ...prevFilters,
          [filterType]: checked
            ? [...filterArray, value]
            : filterArray.filter((item) => item !== value),
        };

        filterlist(updatedFilters);

        return updatedFilters;
      });
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const commonCheckboxHandler = (data, type) => {
    if (filters[type].includes(data)) {
      filters[type] = filters[type].filter((element) => element !== data);
    } else {
      filters[type].push(data);
    }
    setFilters({ ...filters, type: filters[type] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filterlist(filters);
  };
  return (
    <div>
      <div className="families">
        <CommonNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="familybanner">
              <h1 style={{ paddingBottom: "5rem" }}>
                MAKING SENIOR CARE SIMPLE
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="listinginfo">
        <div className="container">
          <div className="row">
            <h1 className="topheading" style={{ textTransform: "capitalize" }}>
              Adult Family Homes in {city}
            </h1>

            {/* Offcanvas Button for Mobile View */}
            <Button onClick={handleShow} className="d-lg-none offcanvasfilter">
              <i className="fa-solid fa-list"></i>
              <span style={{ paddingLeft: "15px" }}>Filter</span>
            </Button>

            {/* Offcanvas Component */}
            <Offcanvas show={show} onHide={handleClose} className="d-lg-none">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filter By</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <form
                  role="search"
                  method="get"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <div className="input-container">
                    <input
                      type="search"
                      className="search-input"
                      value={filters.city}
                      name="city"
                      placeholder="Search By City Name"
                      onChange={(e) =>
                        setFilters({ ...filters, city: e.target.value })
                     
                      }
                      
                    />
                    <button
                      type="button"
                      className="search-submit"
                      name="submit"
                    >
                      <i className="material-icons">search</i>
                    </button>
                  </div>
                </form>
                <h2>Room Type</h2>
                <div className="row">
                  {filterRoom.map((rooms, index) => (
                    <div className="col-12" key={index}>
                      <input
                        defaultValue={rooms}
                        type="checkbox"
                        id={rooms}
                        name={rooms}
                        value={rooms}
                        className="checkboxinput"
                        onClick={() =>
                          commonCheckboxHandler(rooms, "roomTypes")
                        }
                      />

                      <label htmlFor={rooms} className="custom-control-label">
                        {rooms}
                      </label>

                      {rooms}
                    </div>
                  ))}
                </div>
                <hr />
                <h2>Amenities</h2>
                <div className="row">
                  {amenities.map((amenitie, index) => (
                    <div className="col-12" key={index}>
                      <input
                        type="checkbox"
                        id={`amenity-${index}`}
                        name={`amenity-${index}`}
                        value={amenitie}
                        className="checkboxinput"
                        onClick={() =>
                          commonCheckboxHandler(amenitie, "amenities")
                        }
                      />
                      {amenitie}
                    </div>
                  ))}
                </div>
                <hr />
                <h2>Activities</h2>
                <div className="row">
                  {activities.map((activitys, index) => (
                    <div className="col-12" key={index}>
                      <input
                        type="checkbox"
                        id={`activity-${index}`}
                        name={`activity-activities-${index}`}
                        value={activitys}
                        className="checkboxinput"
                        onClick={() =>
                          commonCheckboxHandler(activitys, "activities")
                        }
                      />
                      {activitys}
                    </div>
                  ))}
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            {/* Regular Filter Section for Desktop View */}
            <div className="col-lg-4 col-md-12 col-12 d-none d-lg-block">
              <div className="filtersec">
                <form role="search" method="get" onSubmit={handleSubmit}>
                  <div className="input-container">
                    <input
                      type="search"
                      className="search-input"
                      value={filters.city}
                      name="city"
                      placeholder="Search By City Name"
                      onChange={(e) =>
                        setFilters({ ...filters, city: e.target.value })
                      }
                    />
                    <button
                      type="submit"
                      className="search-submit"
                      name="submit"
                    >
                   <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </form>

                <h1 className="">Filter By</h1>
                <h2>Room Type</h2>
                <div className="row">
                  {filterRoom.map((rooms, index) => (
                    <div className="col-lg-12" key={index}>
                      <input
                        type="checkbox"
                        id={rooms}
                        name={rooms}
                        value={rooms}
                        className="checkboxinput"
                        // onChange={handleChange}
                        onClick={() =>
                          commonCheckboxHandler(rooms, "roomTypes")
                        }
                      />
                      {/* <span style={{ fontSize: "15px" }}>{rooms}</span> */}
                      {rooms}
                    </div>
                  ))}
                </div>
                <hr />
                <h2>Amenities</h2>
                <div className="row">
                  {amenities.map((amenitie, index) => (
                    <div className="col-12" key={index}>
                      <input
                        type="checkbox"
                        id={`amenity-${index}`}
                        name={`amenity-amenities-${index}`}
                        value={amenitie}
                        className="checkboxinput"
                        // onChange={handleChange}
                        onClick={() =>
                          commonCheckboxHandler(amenitie, "amenities")
                        }
                      />
                      {amenitie}
                    </div>
                  ))}
                </div>
                <hr />
                <h2>Activities</h2>
                <div className="row">
                  {activities.map((activitys, index) => (
                    <div className="col-12" key={index}>
                      <input
                        type="checkbox"
                        id={`activity-${index}`}
                        name={`activity-activities-${index}`}
                        value={activitys}
                        className="checkboxinput"
                        // onChange={handleChange}
                        onClick={() =>
                          commonCheckboxHandler(activitys, "activities")
                        }
                      />
                      {activitys}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* House List */}
            <div className="col-lg-8 col-md-12 col-12">
              {homeDetails.length > 1 ? (
                homeDetails.map((homelist, index) => (
                  <div className="list-house" key={index}>
                    <div className="row">
                      <div className="col-lg-6 col-md-4 col-12">
                        <div className="house-img">
                          <a
                            href={homelist.imagePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={homelist.imagePath}
                              className="img-fluid"
                              // style={{ borderRadius: "10px" }}
                              alt="House"
                            />
                          </a>
                          <button
                            className="heartbtn"
                            onClick={() => handleWishlistToggle(index)}
                          >
                            {homelist.isWishlited === true ? (
                              <i class="fa-solid fa-heart hearticon"></i>
                            ) : (
                              <i
                                className={`fa-heart hearticon ${
                                  wishlist[index] ? "fa-solid" : "fa-regular"
                                }`}
                              ></i>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-8 col-12">
                        <div className="home-desp">
                          <div className="home-name">
                            <h3>{homelist.pgName}</h3>
                          </div>
                          <div className="reviews">
                            <div className="reviewbtn">
                              {parseFloat(homelist.avgRating)}
                            </div>

                            <p className="reviewheading">
                              Review Score{" "}
                              <span>{homelist.viewCount} Reviews</span>
                            </p>
                          </div>
                          <div className="servies-provided">
                            <div className="services-given">
                              <i class="fa-solid fa-wifi"></i>
                            </div>
                            <div className="services-given">
                              <i class="fa-solid fa-wheelchair"></i>
                            </div>
                            <div className="services-given">
                              <i class="fa-solid fa-truck-medical"></i>
                            </div>
                          </div>
                          <ReadMore
                            text={homelist.description}
                            maxLength={150}
                          />

                          {/* <Link to="/list-home-LandingPage" className="morebtn">
                            See More Details
                          </Link> */}
                          <Link
                            to={`/list-home-LandingPage/${homelist.stayId}`} // Updated Link
                            className="morebtn"
                            state={{ homeDetails: homelist }} // Pass the homeDetails
                          >
                            See More Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="empty-data">No data found!</p>
              )}
              {homeDetails.length > 1 && (
                <PaginationWrapper
                  pageCount={pageCount}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomeListingFilter;
