import React, { useState, useEffect } from "react";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import "./SignIn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as API from "../../Components/util/url";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../../src/Components/util/store/slices/authSlice";
import Otpmodal from "../../Components/Otp-Modal/Otpmodal";
import CommonModal from "../CommonModal/CommonModal";
import CommonModalFail from "../CommonModal/CommonModalFail";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setUserId } from "../../Components/util/store/wishlistSlice";
const SignIn = () => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false);
  const [signUpMode, setSignUpMode] = useState(false);
  const [modalShowotp, setModalShowotp] = useState(false);
  const [emailsend, setEmailSend] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [showpasswordconfirm, setShowPasswordconfirm] = useState(false);
  const [storesignup, setStoreSignUp] = useState([]);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });

  useEffect(() => {
    if (location.pathname === "/signup") {
      setSignUpMode(true);
    } else {
      setSignUpMode(false);
    }
  }, [location]);

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }
  // function onChangeinput(event) {
  //   const emailValue = event.target.value;
  //   setEmailSend(emailValue);
  //   console.log(emailsend);
  // }
  const handleSignUpClick = () => {
    setSignUpMode(true);
  };

  const handleSignInClick = () => {
    setSignUpMode(false);
  };

  // Formik setup for Sign In
  const signInFormik = {
    initialValues: {
      // email:localStorage.getItem('email'),
      // password: storesignup.password || " ",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("*email is required"),
      password: Yup.string().required("*Password is required"),
    }),
    // onSubmit: (values) => {
    //   console.log("Sign in values:", values);
    // },
  };

  // Formik setup for Sign Up
  const signUpFormik = {
    initialValues: {
      firstname: "",
      lastname: "",
      // phoneNumber: "",
      // email: "",
      // userType: "",
      // password: "",
      // confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("*First name is required"),
      lastname: Yup.string().required("*Last name is required"),
      phoneNumber: Yup.string().required("*Mobile number is required"),
      email: Yup.string()
        .email("*Invalid email address")
        .required("*Email is required"),
      // userType: Yup.string().required("*User type is required"),
      password: Yup.string().required("*Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    // onSubmit: (values) => {
    //   console.log("Sign up values:", values);
    // },
  };

  // signup api integration
  const onSubmitsignup = async (data) => {
    setEmailSend(data.email);
    setModalShowotp(true);
    console.log(modalShowotp);

    try {
      let response = await API.SignUp({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password,
        phoneNumber: data.phoneNumber,
      });
      console.log(response, "signup response");
      setStoreSignUp(response);

      //   if (response.data.map && response.data.map.message === "success"){
      //     localStorage.setItem("email", response.data.map.email);
      //  }

      if (response.data.status === true) {
        setMessage({ ...message, msg: response.data.message });
        // setShowSuccessModal(true);
        localStorage.setItem("email", response.map.email);
        toast.success(response.data.message);
        navigate("/signin");
      } else {
        toast.error(response.data.status);
      }
      if (response.data.status === false) {
        setMessage({ ...message, msg: response.data.map.status });
        setShowFailureModal(true);
        setModalShowotp(false);
      }
      console.log(response, "rsp");
      // navigate("/signin");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred during sign up.");
    }
  };
  // useEffect(() => {
  //   // onSubmitsignup();
  // }, []);
  // signin api integration
  const onSubmitsignin = async (data) => {
    console.log(data, ".....");
    setEmailSend();

    try {
      let response = await API.SignIn({
        email: data.email,
        password: data.password,
      });
      console.log(response, "rsp");

      if (response.data.status === false) {
        setMessage({ ...message, msg: response.data.map.status });
        setShowFailureModal(true);
        toast.error(response.data.map.status);
      }

      // Check if response.data.map is defined and contains the expected properties
      if (response.data.map && response.data.map.message === "success") {
        localStorage.setItem("afhnew", response.data.map.AccessToken);
        localStorage.setItem("email", response.data.map.email);
        localStorage.setItem("userId", response.data.map.userId);

        localStorage.setItem("firstname", response.data.map.firstName);
        localStorage.setItem("lastname", response.data.map.lastName);
        localStorage.setItem("phoneNumber", response.data.map.phoneNumber);

        const roles = response.data.map.roles
          .map((role) => role.name)
          .join(", ");

        localStorage.setItem("Role", roles);

        console.log("Roles:", roles);

        dispatch(
          setAuth({
            email: response.data.map.Name,
            AccessToken: response.data.map.AccessToken,
          })
        );
        toast.success("Login successful!");

        if (roles.includes("ROLE_USER")) {
          console.log(roles.includes("ROLE_USER"), "........");

          navigate("/");
        } else if (roles.includes("ROLE_ADMIN")) {
          navigate("/supernova");
        }
      } else {
        console.log(response.data.map.message);
        toast.error(response.data.map.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred during sign in.");
    }
  };

  // show password
  const togglePassword = () => {
    setShowPassword(!showpassword);
  };
  const togglePasswordconfirm = () => {
    setShowPasswordconfirm(!showpasswordconfirm);
  };

  // handle otp modal
  // const handleOtpmodal=() => {
  //   setModalShowotp(true);
  // };
  return (
    <div className={`sign-in-container ${signUpMode ? "sign-up-mode" : ""}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <div className="signin-margin">
            {!signUpMode && (
              <Formik
                initialValues={signInFormik.initialValues}
                validationSchema={signInFormik.validationSchema}
                onSubmit={onSubmitsignin}
                validateOnChange={true}
                validateOnBlur={true}
              >
                {({ values }) => (
                  <Form className="sign-in-form">
                    <h2 className="title">Sign in</h2>
                    <div className="input-field">
                      <i className="fas fa-user"></i>
                      <Field
                        type="text"
                        name="email"
                        placeholder="Username"
                        // value={savedEmail}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                    <div className="input-field">
                      <i className="fas fa-lock"></i>
                      <Field
                        type={showpassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                      />
                    </div>
                    <div className="showpass">
                      <input
                        type="checkbox"
                        checked={showpassword}
                        onClick={togglePassword}
                      />
                      <span>Show Password</span>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                    <button type="submit" className="btns solid">
                      Login
                    </button>
                    <p className="social-text">
                      Or <button onClick={handleSignUpClick}> signup</button>
                    </p>
                    <NavLink to="/forgetpassword">
                      <p className="resend text-muted mb-0">
                        Did you Forget Password?{" "}
                        <button className="resend-otp"
                       
                        >Forget Password</button>
                      </p>
                    </NavLink>
                  </Form>
                )}
              </Formik>
            )}
          </div>

          {signUpMode && (
            <Formik
              initialValues={signUpFormik.initialValues}
              validationSchema={signUpFormik.validationSchema}
              onSubmit={onSubmitsignup}
              validateOnChange={true}
              validateOnBlur={true}
            >
              <Form className="sign-up-form">
                <h2 className="title mt-5">Sign Up</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                  />
                </div>
                <ErrorMessage
                  name="firstname"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field type="text" name="lastname" placeholder="Last Name" />
                </div>
                <ErrorMessage
                  name="lastname"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fa-solid fa-phone"></i>
                  <Field
                    type="text"
                    name="phoneNumber"
                    placeholder="Mobile Number"
                  />
                </div>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fa-solid fa-envelope-open"></i>
                  <Field
                    type="email"
                    name="email"
                    // onChange={onChangeinput}
                    placeholder="Email"
                  />
                </div>
                <ErrorMessage name="email" component="div" className="error" />
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field as="select" name="userType">
                    <option value="lookingForCare">Looking for Care</option>
                    <option value="homeOwner">Home Owner</option>
                  </Field>
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <Field
                    type={showpassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <div className="showpass">
                  <input
                    type="checkbox"
                    checked={showpassword}
                    onClick={togglePassword}
                  />
                  <span>Show Password</span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <Field
                    type={showpasswordconfirm ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="showpass">
                  <input
                    type="checkbox"
                    checked={showpasswordconfirm}
                    onClick={togglePasswordconfirm}
                  />
                  <span>Show Password</span>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChange}
                />
                <button type="submit" className="btns">
                  Verify by Email
                </button>

                <p className="social-text">
                  Or <button onClick={handleSignInClick}> signIn</button>
                </p>
              </Form>
            </Formik>
          )}
        </div>
      </div>

      <div className="panels-container">
        <div className={`panel left-panel ${signUpMode ? "hide" : ""}`}>
          <div className="content">
            <h3>New to our community?</h3>
            <p>
              Discover a world of possibilities! Join us and explore a vibrant
              community where ideas flourish and connections thrive.
            </p>
            <button
              className="btns transparent btnresp"
              onClick={handleSignUpClick}
            >
              Sign up
            </button>
          </div>
          <img
            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1722248241/Privacy-policy-rafikinew_xthgqi.png"
            className="image"
            alt="Sign up illustration"
          />
        </div>

        <div className={`panel right-panel ${!signUpMode ? "hide" : ""}`}>
          <div className="content">
            <h3>One of Our Valued Members</h3>
            <p>
              Thank you for being part of our community. Your presence enriches
              our shared experiences. Let's continue this journey together!
            </p>
            <button className="btns transparent" onClick={handleSignInClick}>
              Sign in
            </button>
          </div>
          <img
            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1722082495/Mobile-login-rafikinew_v9ho2f.png"
            className="image"
            alt="Sign in illustration"
          />
        </div>
      </div>
      <Otpmodal
        show={modalShowotp}
        onHide={() => setModalShowotp(false)}
        email={emailsend}
      />
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
      <CommonModalFail
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        message={message}
      />
      <ToastContainer position="top-right" autoClose={5000} theme="colored" />
    </div>
  );
};

export default SignIn;
