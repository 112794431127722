import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Import the CSS file containing the .no-scroll class

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if consent has already been given or declined and stored in localStorage
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      // Show the consent banner after a delay (e.g., 3 seconds)
      const timer = setTimeout(() => {
        setIsVisible(true);
        // document.body.classList.add('no-scroll');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleAcceptAll = () => {
    // Store cookie consent as 'accepted'
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
    // document.body.classList.remove('no-scroll');
  };

  const handleDecline = () => {
    // Store cookie consent as 'declined'
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
    // document.body.classList.remove('no-scroll');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-consent" data-aos="fade-up" data-aos-duration="3000">
      <p>
        We use cookies to improve your experience on our site. This includes cookies for
        essential site functionality, as well as analytics and advertising purposes. By
        clicking "Accept All Cookies," you consent to the storing of cookies on your device
        to enhance site navigation, analyze site usage, and assist in our marketing efforts.
      </p>
      <div className="cookie-buttons">
        <button onClick={handleAcceptAll}>Accept All Cookies</button>
        <button onClick={handleDecline} className='declinebtn'>Decline</button>
      </div>
    </div>
  );
};

export default CookieConsent;
