import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import * as API from "../../Components/util/url";
import { Link, NavLink } from "react-router-dom";
import "./SimilarHome.css";

const SimilarHome = ({ homenearby, seemorehandler }) => {
  const [nearbyhome, setNearByHome] = useState([]); // Declare nearbyhome first
  const [wishlist, setWishlist] = useState({});

  const wishlistHeart = (index) => {
    setWishlist((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const scrollClick = (direction) => {
    setNearByHome((prevFrames) => {
      if (direction === "forward") {
        // Move the first element to the end of the array
        return [...prevFrames.slice(1), prevFrames[0]];
      } else if (direction === "backward") {
        // Move the last element to the beginning of the array
        return [
          prevFrames[prevFrames.length - 1],
          ...prevFrames.slice(0, prevFrames.length - 1),
        ];
      }
      return prevFrames;
    });
  };

  const getNearByHomes = async () => {
    try {
      let response = await API.GetNearByHomes({
        latitute: homenearby.latitude,
        longitute: homenearby.longitude,
      });
      setNearByHome(response.data.homeData);
      console.log(response.data.homeData, "15555");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNearByHomes();
  }, []);

  return (
    <div id="test" className="container wrapper ">
      <h1 className="recommened-heading">Similar communities nearby</h1>
      {/* <div className="buttonssimilar">
        <a onClick={() => scrollClick("backward")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </a>
        <a onClick={() => scrollClick("forward")}>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div> */}

      <div className="container d-flex">
        {nearbyhome &&
          nearbyhome.map((data, index) => (
          
            <div className="frame" key={index}>
              <div className="recommended-house">
                <div className="recommended-house-img">
                  <div>{data.stayId}</div>
                  <NavLink to={`/list-home-LandingPage/${data.stayId}`}>
                    <img src={data.imagePath} alt={data.name} />
                  </NavLink>
                </div>
                <div className="recommended-house-desp mt-3">
                  <div className="house-name ">
                    <Link
                      to={`/list-home-LandingPage/${data.stayId}`}
                      className="house-name-link"
                    >
                      {data.pgName}
                    </Link>
                  </div>
                  <div className="house-address mb-3">
                    {/* <NavLink
                      to={`/list-home-LandingPage/${data.stayId}`}
                      className="house-address-link"
                    >
                      {data.address}
                    </NavLink> */}
                  </div>
                  <div className="reviews">
                    <div className="reviewbtn">{data.avgRating}</div>
                    <p className="reviewheading">
                      Review Score <span>{data.viewCount} Reviews</span>
                    </p>
                  </div>
                  <div className="house-desp-recommended">
                    <p>{data.description}</p>
                  </div>
                  <div className="recommended-more-btn"
                  onClick={(stayId)=>seemorehandler(data.stayId)}
                  >
                    <Link
                      to={`/list-home-LandingPage/${data.stayId}`}
                      className="recommended-more-btnlink"
                      state={{ homeDetails: data.stayId}} 
                      
                    >
                      See More Details{" "}
                      <span>
                        <i className="fa-duotone fa-solid fa-angles-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="buttonssimilar">
        <a onClick={() => scrollClick("backward")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </a>
        <a onClick={() => scrollClick("forward")}>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>
    </div>
  );
};

export default SimilarHome;
