import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import AOS from "aos";
import * as API from "../Components/util/url";
import CommonNavbar from "./CommonNavbar";
import "aos/dist/aos.css";
import "../App.css";
import "./Home.scss";
const LandingBanner = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState("");
  const [keyword, setKeyword] = useState([]);
  useEffect(() => {
    AOS.init();
  }, []);
  const phoneNumber = "+1(425)  5772018 ";
  const contactmail = "adultfamilyhomes.org@gmail.com";
  const cityHandler = async (e, condition = "search") => {
    console.log(e);
    if (condition !== "list") {
      e.preventDefault();
      if (city === "" || city === "undefined") return;
    }
    try {
      const data = {
        pageNumber: 1,
        size: 1,
        city,
        roomTypes: [],
        amenities: [],
        activities: [],
      };
      const resp = await API.HomeListFilter(data);

      if (resp.data.success) {
        if (condition !== "list") {
          navigate(`/${city}`);
        } else {
          navigate(`/${e}`);
        }
      } else {
        alert("No home for this city!");
      }
    } catch (err) {
      alert("ERROR::: ", err);
    }
  };

  const searchHandler = (keyword) => {
    setCity(keyword);
    
    let timerOut=setTimeout(()=>{
      filterlist(keyword);
    },800);
    return()=>clearTimeout(timerOut);
  };

  const filterlist = async (data) => {
    try {
      if (data == "") return;
      const response = await API.getCityNames(data);
      console.log(response.data.cities);
      if (response.data.cities.length > 0) {
        setKeyword(response.data.cities);
      }
    } catch (error) {
      console.error("API Call Error:", error.message || error);
      setKeyword([]);
    }
  };

  const singleCityHandler = (keyword) => {
    setCity(keyword);
    cityHandler(keyword, "list");
  };

  return (
    <div className="realtiveoverlay">
          <div className="bannertopnav">
          <div className="contact-infonumber topcontact-no">
              <p><i class="fa-solid fa-phone"></i>
                <NavLink to={`tel:${phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>  <button className=" phnno-btn">
         {phoneNumber}
        </button></NavLink></p>
             
              </div>
              <div className="contactemail contact-infonumber">
              <p><i class="fa-solid fa-envelope"></i>
                <NavLink to={`mailto:${contactmail}`} style={{ textDecoration: 'none', color: 'inherit' }}>  <button className=" phnno-btn email-btn">
         {contactmail}
        </button></NavLink></p>
              </div>
        </div>
      <div className="maindiv">
       
        <div className="overlay"></div>
        <CommonNavbar />
     
        <div className="container ">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 banheading">
              <div className="topheading-ban">
                <div className="stars mt-5">
                   <span className="fa fa-star checked"> </span>
                  <span className="fa fa-star checked"> </span>
                  <span className="fa fa-star checked"> </span>
                  <span className="fa fa-star checked"> </span>
                  <span className="fa fa-star checked"> </span> 
                  {/* <span>LET'S HELP THEM TOGETHER</span> */}
                </div>
              </div>
              <h2 data-aos="fade-right" data-aos-duration="1000" className="bannerques" >
              Looking for a place for your loved one?
              </h2>
              <h1 data-aos="fade-right" data-aos-duration="3000" className="banmainhead">
                Speak To One Of{" "}
                <span className="fancy" style={{ color: "#61ce70" }}>
                  Our Senior{" "}
                </span>
                Placement Specialist Today
              </h1>
              <div style={{ position: "relative" }}>
                <form
                  role="search"
                  method="get"
                  className="search-form form bansearch mb-5"
                  action=""
                >
                  <label className="lablesearch ">
                    <span className="screen-reader-text">Search for...</span>
                    <input
                      type="search"
                      className="search-field"
                      placeholder="Find Adult Family Homes Near You"
                      defaultValue=""
                      name="s"
                      title=""
                      value={city}
                      onChange={(e) => searchHandler(e.target.value)}
                    />
                  </label>
                  <input
                    type="submit"
                    className="search-submit buttonsearch"
                    // defaultValue=""
                    onClick={cityHandler}
                  />
                </form>
                {keyword.length !== 0 && (
                  <div style={{ position: "absolute", top: 55 }}>
                    <ListGroup>
                      {keyword.map((data) => (
                        <ListGroup.Item
                          key={data}
                          style={{ cursor: "pointer" }}
                          onClick={() => singleCityHandler(data)}
                        >
                          {data}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
