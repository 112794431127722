import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useNavigate } from "react-router-dom";
import "../App.css";
import "./Home.scss";
import "../Components/MediaQuery/MediaQuery.css";

const CommonNavbar = ({ style }) => {
  const [show, setShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [ownerrole, setOwnerRole] = useState(false); // State to track login status
  const [userName, setUserName] = useState("");
  const [profileData, setProfileData] = useState({
    firstname: localStorage.getItem("firstname") || "",
  }); // State to store the username
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle login
  const handleLogin = () => {
    // For demonstration, simulate a successful login:
    setIsLoggedIn(true);

    setUserName(userName); // Assuming the username is hardcoded for now

    // Store login status and username in localStorage
    localStorage.setItem("isLoggedIn", true);
    // localStorage.setItem("userName", firstname);

    navigate("/"); // Redirect to homepage after login
  };

  // Handle logout
  const logoutHandler = () => {
    // Clear login state
    setIsLoggedIn(false);
    setUserName("");

    // Remove login status from localStorage
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userName");

    navigate("/"); // Redirect to homepage after logout
  };

  // Check login state from localStorage on component mount
  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedUserName = localStorage.getItem("userName");
    const ownerRole = localStorage.getItem("Role");

    if (storedIsLoggedIn) {
      setIsLoggedIn(true);
      setUserName(storedUserName || ""); // Default to empty string if no username is found
    }

    if (ownerRole) {
      setOwnerRole(true);
    }
  }, []);

  return (
    <div>
      <Navbar style={style} expand="lg" className="">
        <Container>
          <Navbar.Brand href="/" className="navlogo">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
              alt=""
            />
          </Navbar.Brand>
          <Button className="navbar-toggler" type="button" onClick={handleShow}>
            <span className="navbar-toggler-icon"></span>
          </Button>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto navitems">
              <NavLink to="/" className="navlinks nav-link">
                Home
              </NavLink>
              <NavLink to="/families" className="navlinks nav-link">
                Families
              </NavLink>
              <NavLink to="/providers" className="navlinks nav-link">
                Providers
              </NavLink>
              <NavLink to="/medicaid" className="navlinks nav-link">
                Medicaid
              </NavLink>
              <NavLink to="/blog" className="navlinks nav-link">
                Our Blogs
              </NavLink>
              <NavLink to="/contact-us" className="navlinks nav-link">
                Contact Us
              </NavLink>
              <NavLink
                to="/wishlist"
                className="navlinks nav-link"
                style={{ fontSize: "25px", marginTop: "-4px" }}
              >
                <i className="fa-solid fa-heart"></i>
              </NavLink>

              {!isLoggedIn ? (
                // If the user is not logged in, show Sign In and Sign Up buttons
                <>
                  <NavLink to="/signin">
                    <button
                      className="sign-btn btnsall"
                      type="button"
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                  </NavLink>
                  <NavLink to="/signup">
                    <button className="sign-btn btnsall" type="button">
                      Sign Up
                    </button>
                  </NavLink>
                </>
              ) : // If the user is logged in, check if the role is not ROLE_OWNER
              !ownerrole ? (
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      border: "none",
                      fontSize: "20px",
                      color:'#000'
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end animate slideIn mt-4">
                    <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                      Welcome{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {profileData.firstname}
                      </span>
                    </div>
                    <Dropdown.Divider />
                    <div className="px-2 py-1 stripe">
                      <NavLink
                        to="/user/profile"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="me-5"
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Profile
                        </div>
                      </NavLink>
                    </div>
                    <div className="px-2 py-1 stripe">
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                // If the user is ROLE_OWNER
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      border: "none",
                      fontSize: "20px",
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end animate slideIn mt-4">
                    <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                      Welcome{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {profileData.firstname}
                      </span>
                    </div>
                    <Dropdown.Divider />
                    <div className="px-2 py-1 stripe">
                      <NavLink
                        to="/user/profile"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="me-5"
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Profile
                        </div>
                      </NavLink>
                    </div>
                    <div className="px-2 py-1 stripe">
                      <NavLink to='/owner/dashboard' style={{ textDecoration: "none" }}>
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                      >
                         As Owner
                      </div>
                      </NavLink>
                     
                    </div>
                    <div className="px-2 py-1 stripe">
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </div>
                    </div>
                    
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="static"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="staticBackdropLabel" className="navlogo">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1719831655/afhlogo_hwkwdv.png"
              alt="Logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav
            className="m-auto navitems"
            style={{
              color: "#000",
              display: "block",
              textAlign: "center",
              lineHeight: "2",
            }}
          >
            <NavLink to="/" className=" nav-link" style={{ color: "#000" }}>
              Home
            </NavLink>
            <NavLink
              to="/families"
              className="nav-link"
              style={{ color: "#000" }}
            >
              Families
            </NavLink>
            <NavLink
              to="/providers"
              className="nav-link"
              style={{ color: "#000" }}
            >
              Providers
            </NavLink>
            <NavLink
              to="/medicaid"
              className="nav-link"
              style={{ color: "#000" }}
            >
              Medicaid
            </NavLink>
            <NavLink to="/blog" className=" nav-link" style={{ color: "#000" }}>
              Our Blogs
            </NavLink>
            <NavLink
              to="/contact-us"
              className=" nav-link"
              style={{ color: "#000" }}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/wishlist"
              className="navlinks nav-link"
              style={{ fontSize: "25px", marginTop: "-4px", color: "#000" }}
            >
              <i className="fa-solid fa-heart" style={{ color: "#000" }}></i>
            </NavLink>
            {!isLoggedIn ? (
                // If the user is not logged in, show Sign In and Sign Up buttons
                <>
                <div className="offcanvasbtn">
                <NavLink to="/signin">
                    <button
                      className="sign-btn btnsall"
                      type="button"
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                  </NavLink>
                  <NavLink to="/signup">
                    <button className="sign-btn btnsall" type="button">
                      Sign Up
                    </button>
                  </NavLink>
                </div>
                
                </>
              ) : // If the user is logged in, check if the role is not ROLE_OWNER
              !ownerrole ? (
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      border: "none",
                      fontSize: "20px",
                      color:'#000'
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end animate slideIn mt-4">
                    <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                      Welcome{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {profileData.firstname}
                      </span>
                    </div>
                    <Dropdown.Divider />
                    <div className="px-2 py-1 stripe">
                      <NavLink
                        to="/user/profile"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="me-5"
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Profile
                        </div>
                      </NavLink>
                    </div>
                    <div className="px-2 py-1 stripe">
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                // If the user is ROLE_OWNER
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      background: "transparent",
                      border: "none",
                      fontSize: "20px",
                      color:'#000'
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end animate slideIn mt-4">
                    <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                      Welcome{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {profileData.firstname}
                      </span>
                    </div>
                    <Dropdown.Divider />
                    <div className="px-2 py-1 stripe">
                      <NavLink
                        to="/user/profile"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="me-5"
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Profile
                        </div>
                      </NavLink>
                    </div>
                    <div className="px-2 py-1 stripe">
                      <NavLink to='/owner/dashboard' style={{ textDecoration: "none" }}>
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                      >
                         As Owner
                      </div>
                      </NavLink>
                     
                    </div>
                    <div className="px-2 py-1 stripe">
                      <div
                        className="me-5"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={logoutHandler}
                      >
                        Logout
                      </div>
                    </div>
                    
                  </Dropdown.Menu>
                </Dropdown>
              )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CommonNavbar;
